export const ABOUT_CLIENTS = [
  {
    title: 'Vodafone',
    addr: 'Boglarka Dobias, IT Manager - BI & Data Analytics',
    text: 'We sincerely appreciate efficient, gracious customer service, the level of detail ' +
      'and accountability that the CyberVision team has demonstrated on each assignment, and ' +
      'the way they conduct business as a whole. We are delighted by the quality of their ' +
      'responsiveness and professionalism, very reliable. The CyberVision team demonstrated ' +
      'full commitment and professionalism not only in actual deliverables but also during the ' +
      'Knowledge Transfer sessions. Keep up the good job. Thank you so much, and we will be looking ' +
      'forward to an excellent continuing business relationship.'
  },
  {
    title: 'Cloudera',
    addr: 'Sripathi Raj, Former Director of Engineering in Cloudera',
    text: 'I had the opportunity to work with CyberVision during the development of a new product at Cloudera. The CyberVision recruiting team identified and onboarded a team of engineers with the requisite subject matter expertise on short notice. The team ramped up quickly and delivered high quality software on schedule. It\'s one of the best engineering teams I\'ve worked with in my career. I\'m looking forward to working with the CyberVision team again on another venture.'
  },
  {
    title: 'Outset',
    addr: 'Bhavesh Patel, VP of Software',
    text: 'Outset is a pioneering medical technology company focused on changing the way dialysis is delivered. ' +
      'Outset\'s consumer-driven design approach is aimed at service model innovation that will dramatically improve' +
      ' the care experience for patients and lower costs for providers. Outset is a company of ambitious and bold innovators' +
      ' and we look for the same traits in our partners. We have found this partner in CyberVision as well as the best' +
      ' middleware technology which fit our technological demands as well as FDA and HIPAA regulations. The team at ' +
      'CyberVision has proved to be not only experts in the IoT realm, but also in various engineering initiatives ' +
      'including data analytics, telemetry, embedded development, and architecture. CyberVision was able to meet all ' +
      'of our requirements for a successful deployment of our product.'
  },
  {
    title: 'Jawbone',
    addr: 'Tom Donaldson, VP Research and Innovation',
    text: 'Jawbone has worked with CyberVision for several years, covering algorithms, firmware development, iOS/Android development, wifi and Bluetooth. Throughout this time, CyberVision has been able to supply engineers of the highest quality, comfortably matching counterparts in California. CyberVision’s engineers have been dedicated and proactive, requiring relatively lightweight management while producing high quality work and always taking the trouble to do things right. CyberVision’s team have been able to adapt their working styles to integrate well with our working models and when a deliverable is looming, they have always stayed to help get a solution over the line. CyberVision engineers have quickly become part of the family and for many at Jawbone, they have changed preconceptions about offshoring. I would not hesitate to recommend CyberVision for any software development or algorithmic work and they would be my first choice for outsourcing in the future.'
  },
  {
    title: 'Bandwidth',
    addr: 'Doug Neumann, Sr. Director of Systems & Software Engineering',
    text: 'We\'ve been working with CyberVision for multiple years, and they\'ve become a natural extension to our onshore software development team. Their ability to quickly staff development resources with deep technical expertise has been essential to our ability to rapidly innovate, with high quality, across our business. Further, their shifted workday and strong English communication skills enable deep collaboration with our onshore team despite the geographical and cultural distance. CyberVision breaks the mold of outsourced software development, and has changed our opinions of offshoring in a very good way.'
  },
  {
    title: 'Jaybird',
    addr: 'Ben Wisbey, Sports Scientist',
    text: 'We have been extremely impressed by the quality of service offered by CyberVision during our recent project. The CyberVision team were extremely thorough in their testing, reporting and communication. They were easy to work with and we will happily use CyberVision again.'
  },
  {
    title: 'Michael Luna Technologies ',
    addr: 'Michael Luna, Founder',
    text: 'CyberVision team assisted us with building complex a real-time decentralized blockchain-based marketplace. CyberVision was the backbone of the development team, working relentlessly across time zones meet difficult deadlines, solving problems and maintaining a seamless collaboration with the internal team. With their impressive expertise, they delivered results on-time, on-budget and were always ready to work with our requirements regardless of the time. The project included implementing smart contracts, designing and implementing security and consensus layer over a decentralized peer-to-peer computer network, net token models, managing deployment and performance troubleshooting. Highly competent engineers with great attention to detail and great communication skills build trust quickly, and delivery on-time secure it. I have used CyberVision in three companies over decades and I would not hesitate to recommend their services'
  },
  {
    title: 'Orison',
    addr: 'Eric Clifton, President and CEO',
    text: 'Orison is developing smart and scalable plug-and-play energy storage systems. All products are designed to be IoT integrated through the Orison Cloud, which is accessible via our mobile app and software to provide all electricity consumers control over their usage and access to energy in any situation. Our smart, IoT-hub also enables an intelligent, efficient and renewable electricity grid. We engaged CyberVision to develop cloud, analytics, and customer management solutions. We found the CyberVision team to be exceptional partners. Their engineers worked closely with our hardware developers to create an IoT solution that is easy to use, reliable, and secure. We look forward to work together again on our next project.'
  },
  {
    title: 'Mighty Cast',
    addr: 'Adam Adelman, CEO and Co-Founder',
    text: 'We came to CyberVision with a very specific problem which they tackled right away. Not only were they incredibly talented on the tech side, but also completely responsive and transparent on the communication side. Mighty Cast has built solid trust in CyberVision expertise and professionalism and we look forward to doing more projects together.'
  },
  {
    title: 'Predixion Software',
    addr: 'John Crupi, CTO',
    text: 'Cybervision has been part of our R&D team which was developing innovative IoT analytics suite of products. They were responsible for building functionality needed to operate large-scale IoT solutions, which supports massive data volumes in real time and handles all the communication across connected devices, including data consistency and security, device interoperability, and failure-proof connectivity. We are very pleased with the way in which Cybervision team conducted themselves throughout the entire project. Team members took a very active role in development, providing ideas and were eager to make the best product possible The team is very accommodating, professional and expert in their field. The communication was easy, the progress was as planned, and the results were above expectation. We will not hesitate to work with this them again on future projects. I can highly recommend them.'
  },
  {
    title: 'Roundbox',
    addr: 'Dennis Specht, Founder, Co-Chairman, President/CEO',
    text: 'Roundbox a leading provider of cloud-enabled solutions and software that optimize video & data delivery. With CyberVision as our development partner, we were able to build an innovative mobile platform capable of delivering content across multiple mobile technologies and platforms. Over the course of almost a decade-long relationship, CyberVision provided tremendous value to our organization including, engineering staff knowledge, technical skills and professionalism. I look forward to working with CyberVision for many more years to come, and enthusiastically recommend them to any company looking to create new, complex technology-based platforms.'
  },
  {
    title: 'Sensoria',
    addr: 'Mario Esposito, Co-Founder and Innovation Advisor',
    text: 'The team at CyberVision shows their talent and desire to exceed the expectations as a company cultural asset and not because it is just protocol. They are very open to feedback and most important they deliver. I would not hesitate to leverage CyberVision on my next project. Good work shows and customer driven actions.'
  },
  {
    title: 'Seven Networks',
    addr: 'Dr. Ross Bott, President & CEO',
    text: 'After having worked with several major engineering outsourcing companies in both India and the Ukraine, the characteristic of Cybervision which stands out the most is their ability to take on and deliver on unusually deep and complex technology projects and execute at a high level on both design and implementation. Their level of engineering talent is higher than I’ve seen in any such company. And their willingness to put in the extra hours to deliver to high pressure deadlines also makes them a great company to work with in dealing with today’s fast evolving and very competitive wireless markets.'
  },
  {
    title: 'Voxtok',
    addr: 'Jean-Charles Verdié, CTO and Co-Founder',
    text: 'We asked Cybervision to accompany us on a very ambitious project. We expected to find an engineering company and we ended up with a real partner. Their technical expertise brought value to the project and the communication with the team has been a real bonus.'
  }
]

export const PARTNERS = {
  DATASTAX: 'datastax',
  CLOUDERA: 'cloudera',
  CANONICAL: 'canonical',
  AWS: 'aws',
  COUCHBASE: 'couchbase',
  HORTNOWORKS: 'hortonworks',
  MAPR: 'mapR',
  TEXAS_INSTRUMENTS: 'texasInstruments',
  GOOGLE: 'google'
}

export const ABOUT_PARTNERS =
  {
    [PARTNERS.GOOGLE]: ['CyberVision is an official Google SI and PS partner, which attests our full proficiency with the Google Cloud Platform (GCP) technology stack. Google is one of the leading providers of cloud computing infrastructure and applications for diverse enterprise needs, with the strong emphasis on innovative Big Data analytics and AI capabilities. With their dynamically growing GCP ecosystem, in most cases Google enables companies to access all the development components they need to create end-to-end business solutions and ensure their reliable performance.'],
    [PARTNERS.COUCHBASE]: [
      'Couchbase delivers the world’s highest performing NoSQL distributed database platform. Developers around the world use the Couchbase platform to build enterprise web, mobile, and IoT applications that support massive data volumes in real time. The Couchbase platform includes Couchbase Server, Couchbase Lite - the first mobile NoSQL database, and Couchbase Sync Gateway. Couchbase is designed for global deployments, with configurable cross data center replication to increase data locality and availability. All Couchbase products are open source projects. ',
      'Couchbase customers include industry leaders like AOL, AT&T, Bally’s, Beats Music, BSkyB, Cisco, Comcast, Concur, Disney, eBay, KDDI, Nordstorm, Neiman Marcus, Orbitz, PayPal, Rakuten / Viber, Tencent, Verizon, Wells Fargo, Willis Group, as well as hundreds of other household names. ',
      'Couchbase investors include Accel Partners, Adams Street Partners, Ignition Partners, Mayfield Fund, North Bridge Venture Partners, and West Summit.'
    ],
    [PARTNERS.AWS]: [
      'CyberVision, with the Kaa IoT Platform it supports, and Amazon, with its Elastic Compute Cloud (EC2), have partnered up to deliver robust and highly reliable IoT development environment in the cloud. Using EC2, IoT developers can jump start the deployment of Kaa and scale it to millions of connected devices if needed. Vast cloud computing capabilities of EC2 allow enterprises to reach the performance they need and feel secure about the reliability of their Kaa-powered IoT solutions and connected devices.'
    ],
    [PARTNERS.CANONICAL]: ['Ubuntu is the most popular operating system for public and private OpenStack clouds. We are everywhere from smart devices, phones, tablets, desktops, servers to clouds. Our solutions are open source: e.g. Juju, MaaS, etc. Canonical, the company behind Ubuntu, provides commercial support and other solutions. We partner with the Kaa project and Cybervision because we believe they have deep IoT expertise and a great open source IoT platform that anybody can easily run on top of Snappy Ubuntu Core.'],
    [PARTNERS.CLOUDERA]: [
      'Cloudera is revolutionizing enterprise data management with the first unified Platform for Big Data, an enterprise data hub built on Apache Hadoop™. Cloudera offers enterprises one place to store, process and analyze all their data, empowering them to extend the value of existing investments while enabling fundamental new ways to derive value from their data. Founded in 2008, Cloudera was the first and still is the leading provider and supporter of Hadoop for the enterprise. Cloudera also offers software for business critical data challenges including storage, access, management, analysis, security and search. Cloudera works with over 1,400 hardware, software and services partners to meet customers\' big data goals.'
    ],
    [PARTNERS.DATASTAX]: [
      'DataStax delivers Apache Cassandra™ in a database platform purpose built for the performance and availability demands of IOT, web, and mobile applications, giving enterprises a secure always-on database that remains operationally simple when scaled in a single datacenter or across multiple datacenters and clouds.',
      'With more than 500 customers in over 50 countries, DataStax is the database technology of choice for the world’s most innovative companies, such as Netflix, Adobe, Intuit, and eBay. Based in Santa Clara, Calif., DataStax is backed by industry-leading investors including Comcast Ventures, Crosslink Capital, Lightspeed Venture Partners, Kleiner Perkins Caufield & Byers, Meritech Capital, Premji Invest and Scale Venture Partners.'
    ],
    [PARTNERS.HORTNOWORKS]: ['Hortonworks is the only 100-percent open source software provider to develop, distribute and support an Apache Hadoop platform explicitly architected, built and tested for enterprise-grade deployments. Developed by the original architects, builders and operators of Hadoop, Hortonworks stewards the core and delivers the critical services required by the enterprise to reliably and effectively run Hadoop at scale. Our distribution, Hortonworks Data Platform, provides an open and stable foundation for enterprises and a growing ecosystem to build and deploy big data solutions. Hortonworks also provides unmatched technical support, training and certification programs.'],
    [PARTNERS.MAPR]: ['MapR delivers on the promise of Hadoop with a proven, enterprise-grade platform that supports a broad set of mission-critical and real-time production uses. MapR brings unprecedented dependability, ease-of-use and world-record speed to Hadoop, NoSQL, database and streaming applications in one unified Big Data platform. MapR is used across financial services, retail, media, healthcare, manufacturing, telecommunications and government organizations as well as by leading Fortune 100 and Web 2.0 companies.'],
    [PARTNERS.TEXAS_INSTRUMENTS]: ['Texas Instruments Incorporated (TI) is a global semiconductor design and manufacturing company that develops analog ICs and embedded processors. TI has the broadest portfolio of building blocks for IoT nodes and gateways spanning wired and wireless connectivity, microcontrollers, processors, sensing technology, power management and analog solutions – along with an ecosystem of cloud service providers to help our customers get connected to the cloud faster. By employing the world’s brightest minds, TI creates innovations that shape the future of technology. TI is helping more than 100,000 customers transform the future, today.']
  }

export const PARTNERS_MAP = {
  1: PARTNERS.GOOGLE,
  2: PARTNERS.AWS,
  3: PARTNERS.MAPR,
  4: PARTNERS.CLOUDERA,
  5: PARTNERS.TEXAS_INSTRUMENTS,
  6: PARTNERS.DATASTAX,
  7: PARTNERS.HORTNOWORKS,
  8: PARTNERS.CANONICAL,
  9: PARTNERS.COUCHBASE
}


