import React from 'react';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import styles from './BigHorizontalCard.module.scss';

const BigHorizontalCard = props => {
  const {
    node,
    index,
    data,
    svgImg,
    borderRight,
    borderBottom,
    borderRadius,
    reverse,
    shadow,
    background = '#fff',
  } = props;
  return (
    <div
      className={styles['content']}
      key={index}
      style={{
        boxShadow: shadow && '0 8px 60px rgba(24, 31, 73, 0.14)',
        borderBottom: borderBottom && '1px solid #DFE4ED',
        borderRadius: borderRadius && '16px',
        background: background,
      }}
    >
      <div
        className={styles['text']}
        style={{
          borderRight: borderRight && '1px solid #DFE4ED',
          order: reverse && 2,
        }}
      >
        <Typography variant="h3">
          {data[Number(node.name) - 1].title}
        </Typography>
        <Typography variant="subtitle1">
          {data[Number(node.name) - 1].addr}
        </Typography>
        <Typography>{data[Number(node.name) - 1].text}</Typography>
      </div>
      <div className={styles['logo']} style={{ order: reverse && 1 }}>
        {svgImg ? (
          <img src={node.publicURL} alt={node.name} className={styles['img']} />
        ) : (
          <Img
            className={styles['img']}
            fluid={node.childImageSharp.fluid}
            alt={node.name}
          />
        )}
      </div>
    </div>
  );
};

export default BigHorizontalCard;
